<template>
  <div>
    <b-container fluid class="borderColor borderColorBackground">
      <b-row>
        <div class="router-links" style="padding-left: 5px">
          <div
            class="vol-income link datepickerButton"
            :class="{ active: activeOrder === 0 }"
            @click="selectAnalysis(0, '發電量')"
          >
            發電量
          </div>

          <div
            class="vol-income link datepickerButton"
            :class="{ active: activeOrder === 1 }"
            @click="selectAnalysis(1, '發電收入')"
          >
            發電收入
          </div>

          <div
            class="vol-income link datepickerButton"
            :class="{ active: activeOrder === 2 }"
            @click="selectAnalysis(2, '妥善率')"
          >
            妥善率
          </div>

          <div
            class="vol-income link datepickerButton"
            :class="{ active: activeOrder === 3 }"
            @click="selectAnalysis(3, 'PR值')"
          >
            PR值
          </div>

          <div
            class="vol-income link datepickerButton"
            :class="{ active: activeOrder === 4 }"
            @click="selectAnalysis(4, '監控值與電費單比較')"
            style="max-width: 200px"
          >
            監控值與電費單比較
          </div>

          <div
            class="vol-income link datepickerButton"
            :class="{ active: activeOrder === 5 }"
            @click="selectAnalysis(5, '資料妥善率')"
          >
            資料妥善率
          </div>
        </div>
      </b-row>
      <b-row
        :no-gutters="true"
        style="display: flex; flex-wrap: wrap; justify-content: center"
      >
        <div
          class="time-group"
          style="display: flex; flex-wrap: wrap"
          v-if="chartTitle !== '監控值與電費單比較'"
        >
          <div
            class="start-time checkRwd"
            v-if="dateCateActive !== 0"
            style="
              display: flex;
              align-items: center;
              color: var(--normaltextcolor);
            "
          >
            選擇區間
            <input
              type="checkbox"
              id="checkbox"
              v-model="chooseDate"
              style="width: 20px; height: 20px"
            />
          </div>
          <div style="display: flex; align-items: center" v-if="!chooseDate">
            <div class="start-time"></div>
            起始年月：<date-picker
              v-model="datepickerDate"
              type="month"
              placeholder="Select month"
              valueType="format"
              format="YYYY-MM"
              style="width: 120px"
              :disabled-date="disabledDate"
            ></date-picker>
          </div>
          <div style="display: flex" v-if="chooseDate">
            <div class="start-time"></div>
            <date-picker
              v-model="beginDate"
              type="month"
              placeholder="Select month"
              valueType="format"
              format="YYYY-MM"
              style="width: 120px"
              :disabled-date="disabledDate"
            ></date-picker>
            <div class="start-time">~</div>
            <date-picker
              v-model="endDate"
              type="month"
              placeholder="Select month"
              valueType="format"
              format="YYYY-MM"
              style="width: 120px"
              :disabled-date="disabledDate"
            ></date-picker>
          </div>
          <button @click="buttonConfirm" class="confirmButton">
            {{ $t("deviceStatus.confirm") }}
          </button>
        </div>
      </b-row>
    </b-container>

    <b-container fluid class="pb-4">
      <component :is="currentComponentName" :ref="currentComponentName" :filename="`投資分析_${chartTitle}`" />
    </b-container>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import Etoday from './investComponents/Etoday.vue'
import PowerGenerationRevenue from './investComponents/PowerGenerationRevenue.vue'
import PrepareRate from './investComponents/PrepareRate.vue'
import PerformantRate from './investComponents/PR.vue'
import Monitor from './investComponents/Monitor.vue'
import DataRetention from './investComponents/DataRetention.vue'
export default {
  name: 'dataanalysis',
  components: {
    Etoday,
    PowerGenerationRevenue,
    PrepareRate,
    PerformantRate,
    Monitor,
    DataRetention
  },
  data () {
    return {
      chartTitle: '發電量',
      activeOrder: 0,
      dateCateActive: 2,
      format: 'yyyy-MM',
      datepickerDate: DateTime.local()
        .minus({ months: 11 })
        .toFormat('yyyy-MM'),
      beginDate: DateTime.local().minus({ months: 11 }).toFormat('yyyy-MM'),
      endDate: DateTime.local().toFormat('yyyy-MM'),
      searchDateArr: [],
      chooseDate: false,
      confirmButton: true
    }
  },
  computed: {
    ...mapState(['factoryData']),
    currentComponentName () {
      if (this.chartTitle === '發電量') return 'Etoday'
      if (this.chartTitle === '發電收入') return 'PowerGenerationRevenue'
      if (this.chartTitle === '妥善率') return 'PrepareRate'
      if (this.chartTitle === 'PR值') return 'PerformantRate'
      if (this.chartTitle === '監控值與電費單比較') return 'Monitor'
      return 'DataRetention'
    }
  },
  methods: {
    ...mapMutations(['changeDataAnalysisLang']),
    ...mapActions(['asyncLastSingleDoc']),
    disabledDate (date) {
      const today = new Date()
      today.setDate(today.getDate())
      today.setHours(0, 0, 0, 0)
      return date > new Date(today.getTime())
    },
    selectAnalysis (selected, routerName) {
      this.activeOrder = selected
      this.chartTitle = routerName
      this.$nextTick(function () {
        this.buttonConfirm()
      })
    },
    buttonConfirm () {
      this.buildSearchDate()
      if (this.chartTitle === '發電量') {
        this.$refs.Etoday.getEtodayData(this.searchDateArr)
      }
      if (this.chartTitle === '發電收入') {
        this.$refs.PowerGenerationRevenue.getEtodayData(this.searchDateArr)
      }
      if (this.chartTitle === '妥善率') {
        this.$refs.PrepareRate.getAvailability(this.searchDateArr)
      }
      if (this.chartTitle === 'PR值') {
        this.$refs.PerformantRate.getPrData(this.searchDateArr)
      }
      if (this.chartTitle === '資料妥善率') {
        this.$refs.DataRetention.getRetention(this.searchDateArr)
      }
    },
    buildSearchDate () {
      this.searchDateArr = []
      if (this.chooseDate) {
        const diff = DateTime.fromISO(this.endDate).diff(
          DateTime.fromISO(this.beginDate),
          'months'
        ).months
        if (diff < 0) {
          this.$swal('請選擇正確區間')
          return
        }
        for (let i = 0; i <= diff; i++) {
          this.searchDateArr.push(
            DateTime.fromISO(this.beginDate)
              .plus({ months: i })
              .toFormat('yyyy-MM')
          )
        }
      } else {
        for (let i = 0; i < 12; i++) {
          this.searchDateArr.push(
            DateTime.fromISO(this.datepickerDate)
              .plus({ months: i })
              .toFormat('yyyy-MM')
          )
        }
      }
    }
  },
  async created () {
    if (!this.factoryData.length) {
      await this.asyncLastSingleDoc(this.$route.query.siteName)
    }
  },
  async mounted () {
    this.buttonConfirm()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/DataAnalysis.scss";
</style>
