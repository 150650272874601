<template>
  <div>
    <div
      class="borderColor borderColorBackground"
      style="margin-top: 10px; margin-bottom: 10px"
    >
      <div class="text-center titlebackgrondstyle"></div>
      <div style="display: flex; align-items: center; margin: 15px">
        <!-- <p class="highChartTitle">發電量</p> -->
        <b-form-checkbox-group
          v-model="dataClassArr"
          :options="dataClass"
          switches
        />
        <button
          class="confirmButton"
          style="width: 120px; margin-left: auto"
          @click="downloadAnalysis"
        >
          資料下載
        </button>
      </div>
      <div
        v-if="hasNoData"
        style="
          background: white;
          min-height: 350px;
          text-align: center;
          line-height: 300px;
        "
      >
        尚無資料
      </div>
      <div v-else>
        <highcharts
          :options="eTodayChart"
          ref="chart"
          style="background: white; min-height: 350px"
        ></highcharts>
      </div>
    </div>
    <div class="borderColor">
      <b-table
        :items="etodayData"
        :fields="tableField"
        hover
        :responsive="true"
        :small="small"
        class="tableStyle"
      >
        <template #cell()="data">
          {{ data.value === "" ? "-" : data.value }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import downloadCsv from 'download-csv'
import { BTable } from 'bootstrap-vue'
import { getPowerAmountApi } from '../../api'
import { dbDataHandle } from '../../store/function/common/index'
export default {
  name: 'Elect',
  components: {
    BTable
  },
  props: {
    filename: {
      type: String,
      default: '下載檔案'
    }
  },
  data () {
    return {
      hasNoData: false,
      small: true,
      etodayData: [],
      tableField: [
        { key: 'date', label: '月份' },
        { key: 'acpGoal', label: '目標發電量 (kWh)' },
        { key: 'acpActual', label: '實際發電量 (kWh)' },
        { key: 'guaranteePower', label: '保證發電量 (kWh)' },
        { key: 'irrGoal', label: '目標日照量 (kWh/m2)' },
        { key: 'irrActual', label: '實際日照量 (kWh/m2)' },
        { key: 'accAcpGoal', label: '目標累積發電量 (kWh)' },
        { key: 'accAcpAll', label: '實際累積發電量 (kWh)' },
        { key: 'accGuaranteePower', label: '保證累積發電量 (kWh)' }
      ],
      dataClassArr: ['發電量', '日照量', '累積發電量'],
      dataClass: ['發電量', '日照量', '累積發電量'],
      eTodayChart: {
        chart: {
          backgroundColor: null,
          events: {
            load () {
              this.showLoading()
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: false
        },
        yAxis: [
          {
            title: {
              text: '發電量 (kWh)'
            }
          },
          {
            title: {
              text: '日照量 (kWh/m2)'
            },
            opposite: true
          },
          {
            title: {
              text: '累積發電量 (kWh)'
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        series: [
          {
            color: 'rgb(255, 192, 0)',
            type: 'column',
            name: '目標發電量 (kWh)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(124,181,236)',
            type: 'column',
            name: '實際發電量 (kWh)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(31, 209, 150)',
            type: 'column',
            name: '保證發電量 (kWh)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(155, 102, 196)',
            name: '目標日照量 (kWh/m2)',
            data: [],
            yAxis: 1,
            visible: true
          },
          {
            color: 'rgb(230, 101, 146)',
            name: '實際日照量 (kWh/m2)',
            data: [],
            yAxis: 1,
            visible: true
          },
          {
            color: 'rgb(255, 162, 0)',
            name: '目標累積發電量 (kWh)',
            data: [],
            yAxis: 2,
            visible: true
          },
          {
            color: 'rgb(86, 160, 232)',
            name: '實際累積發電量 (kWh)',
            data: [],
            yAxis: 2,
            visible: true
          },
          {
            color: 'rgb(2, 181, 122)',
            name: '保證累積發電量 (kWh)',
            data: [],
            yAxis: 2,
            visible: true
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  y: 0
                }
              }
            }
          ]
        }
      },
      etodayDataForExcel: []
    }
  },
  methods: {
    async getEtodayData (months) {
      this.eTodayChart.series.forEach((x) => {
        x.data = []
      })
      const etodayData = await getPowerAmountApi(
        this.$router.history.current.query.fid,
        this.$router.history.current.query.siteName + '.json',
        months
      )
      if (etodayData?.data?.success) {
        // 複製 etodayData、etodayDataForExcel 不能 refer 同個 data
        // renderData() 會改動 etodayData 資料

        // 下載資料用
        this.etodayDataForExcel = etodayData.data.data

        // 顯示圖表、表格用，copy 避免改動到 rawData (etodayData.data.data)
        this.etodayData = etodayData.data.data.map((item) => {
          const copy = Object.assign({}, item)
          return copy
        })

        this.renderData()
      } else {
        this.hasNoData = true
      }
    },
    renderData () {
      this.eTodayChart.xAxis.categories = []
      this.eTodayChart.series.forEach((x) => {
        x.data = []
      })
      this.etodayData.forEach((x) => {
        this.eTodayChart.xAxis.categories.push(x.date)
        // 圖表、表格 是共用 this.etodayData 資料，直接 dbDataHandle 改變原始資料，顯示處理過的數據
        x.acpGoal = x.acpGoal === null ? null : dbDataHandle(x.acpGoal)
        x.acpActual = x.acpActual === null ? null : dbDataHandle(x.acpActual)
        x.guaranteePower =
          x.guaranteePower === null ? null : dbDataHandle(x.guaranteePower)
        x.irrGoal = x.irrGoal === null ? null : dbDataHandle(x.irrGoal)
        x.irrActual = x.irrActual === null ? null : dbDataHandle(x.irrActual)
        x.accAcpGoal =
          x.accAcpGoal === null ? null : dbDataHandle(x.accAcpGoal)
        x.accAcpAll = x.accAcpAll === null ? null : dbDataHandle(x.accAcpAll)
        x.accGuaranteePower =
          x.accGuaranteePower === null
            ? null
            : dbDataHandle(x.accGuaranteePower)
        this.eTodayChart.series[0].data.push(x.acpGoal)
        this.eTodayChart.series[1].data.push(x.acpActual)
        this.eTodayChart.series[2].data.push(x.guaranteePower)
        this.eTodayChart.series[3].data.push(x.irrGoal)
        this.eTodayChart.series[4].data.push(x.irrActual)
        this.eTodayChart.series[5].data.push(x.accAcpGoal)
        this.eTodayChart.series[6].data.push(x.accAcpAll)
        this.eTodayChart.series[7].data.push(x.accGuaranteePower)
      })
      this.hideHighChartLoading()
    },
    hideHighChartLoading () {
      this.$nextTick(function () {
        if (this.$refs && this.$refs.chart) {
          this.$refs.chart.chart.hideLoading()
          this.eTodayChart.chart.events = {}
        }
      })
    },
    downloadAnalysis () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.eTodayChart.xAxis.categories)
      this.eTodayChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })

      const download = []
      this.eTodayChart.series.forEach((_) => {
        download.push({ data: [] })
      })

      this.etodayDataForExcel.forEach((obj) => {
        const acpGoal = obj.acpGoal === null ? null : obj.acpGoal
        const acpActual = obj.acpActual === null ? null : obj.acpActual
        const guaranteePower =
          obj.guaranteePower === null ? null : obj.guaranteePower
        const irrGoal = obj.irrGoal === null ? null : obj.irrGoal
        const irrActual = obj.irrActual === null ? null : obj.irrActual
        const accAcpGoal = obj.accAcpGoal === null ? null : obj.accAcpGoal
        const accAcpAll = obj.accAcpAll === null ? null : obj.accAcpAll
        const accGuaranteePower = obj.accGuaranteePower === null ? null : obj.accGuaranteePower
        download[0].data.push(acpGoal)
        download[1].data.push(acpActual)
        download[2].data.push(guaranteePower)
        download[3].data.push(irrGoal)
        download[4].data.push(irrActual)
        download[5].data.push(accAcpGoal)
        download[6].data.push(accAcpAll)
        download[7].data.push(accGuaranteePower)
      })

      this.eTodayChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = download[yindex].data[xindex]
        })
      })

      downloadCsv(downloadArr, fields, this.filename)
    }
  },
  mounted () {},
  watch: {
    dataClassArr: function (newValue, oldValue) {
      const setData = [
        {
          name: '發電量',
          dataName: [
            '目標發電量 (kWh)',
            '實際發電量 (kWh)',
            '保證發電量 (kWh)'
          ]
        },
        {
          name: '日照量',
          dataName: ['目標日照量 (kWh/m2)', '實際日照量 (kWh/m2)']
        },
        {
          name: '累積發電量',
          dataName: [
            '目標累積發電量 (kWh)',
            '實際累積發電量 (kWh)',
            '保證累積發電量 (kWh)'
          ]
        }
      ]
      if (oldValue !== newValue) {
        this.eTodayChart.series.forEach((x) => {
          x.visible = false
        })
        setData.forEach((x) => {
          if (newValue.includes(x.name)) {
            x.dataName.forEach((y) => {
              this.eTodayChart.series.find((z) => z.name === y).visible = true
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.test {
  .thead th {
    text-align: center !important;
  }
}
@import "../../assets/scss/global.scss";
</style>
